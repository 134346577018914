@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Exposure";
  src: url("/public/fonts/Exposure.ttf"), format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background: #212121;
}

.noDrag {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a,
button {
  transition: all 0.3s ease;
}
